import React from "react";
import Main from "../../components/layouts/Main";
import Breadcrumb from "../../components/breadcrumb";
import serviceBg from "../../images/service-breadcrumb.jpg"
import Service from "../../components/service/service";
import {graphql} from "gatsby";
import {useTranslation, useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../../components/seo";
import SeoLogo from "../../images/seo-logo.png"
const Services = () => {
    const { t } = useTranslation();
    const{ language } = useI18next();
    const url = language === 'hy' ? 'https://solidaccount.am/services' : `https://solidaccount.am/${language}/services`
    return (
       <Main>
           <Seo title={t('Services')} url={url} logo={SeoLogo} />
           <Breadcrumb cover={serviceBg} title={'Services'} />
           <Service from={'service'} />
       </Main>
    )
};

export default Services
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;